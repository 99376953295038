import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

const FormSelector = loadable(() => import('/src/components/Form/FormSelector'))
const LargeCard = loadable(() => import('/src/components/Cards/LargeCard'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))
const WizardStep = loadable(() => import('/src/components/Form/FormWizard/WizardStep'))

const FormBlock = ({ backgroundColour, titleArea, formIntro, form, formDisclaimer }) => {
    const isDarkBg = backgroundColour === 'dark' || backgroundColour === 'gradient'
    const formOutput = <FormSelector form={form} disclaimer={formDisclaimer} />

    return (
        <Section id={'form'} variant={backgroundColour} titleArea={titleArea} className={'c-form-block'}>
            <div className="row">
                {isDarkBg ? (
                    <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                        <LargeCard>
                            <WizardStep title={formIntro}>{formOutput}</WizardStep>
                        </LargeCard>
                    </div>
                ) : (
                    <div className="col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">{formOutput}</div>
                )}
            </div>
        </Section>
    )
}

FormBlock.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired,
    titleArea: PropTypes.shape({
        addTitleArea: PropTypes.bool,
        title: PropTypes.string,
        description: PropTypes.string
    }),
    formIntro: PropTypes.string,
    form: PropTypes.PropTypes.oneOf([
        'ExistingCustomersForm',
        'ComplaintsForm',
        'PolicyCancellationForm',
        'PressPartnershipsForm',
        'PRPortalForm',
        'TechnicalEnquiryForm',
        'MarketingOptOutForm',
        'MarketingOptInForm',
        'Over50sGuideForm',
        'CustomerFeedbackForm',
        'ClaimVoucherForm',
        'AccessibilityForm',
        'CareersReferForm',
        'CareersReferredForm'
    ]).isRequired,
    formDisclaimer: PropTypes.string
}

FormBlock.defaultProps = {
    backgroundColour: 'light',
    titleArea: {
        addTitleArea: true,
        titlePosition: 'centered',
        title: 'Section Title'
    },
    formIntro: 'Please enter your details below',
    form: 'ExistingCustomersForm',
    formDisclaimer: '<p>Form disclaimer text here</p>'
}

export default FormBlock

export const query = graphql`
    fragment FormBlockForPageBuilder on WpPage_Acfpagebuilder_Layouts_FormBlock {
        fieldGroupName
        backgroundColour
        addTitleArea
        title
        description
        formIntro
        form
        formDisclaimer
    }
`
